<template>
  <div>
    <div class="custom-card tw-rounded-none tw-border-none">
      <div role="status" class="tw-max-w-sm tw-animate-pulse">
        <div
          class="tw-dark:tw-bg-gray-700 tw-mb-2.5 tw-h-2 tw-max-w-[360px] tw-rounded-full tw-bg-gray-200"
        ></div>
        <span class="tw-sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
